import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  SET_PAGE,
  SET_PHONE_NUMBER,
  SET_COUNTRY,
  SET_ERROR_MESSAGE,
  SET_PREFIX_OPTIONS_LIST,
  CLOSE_PHONE_NUMBER_MODAL
} from '../actions/actionTypes';

import { ADD_PHONE_NUMBER_PAGE } from '../constants/pageConstants';
import EventOriginNames from '../constants/loggingConstants';

const initialState = {
  isOpen: true,
  pageName: ADD_PHONE_NUMBER_PAGE,
  errorMessage: '',
  origin: EventOriginNames.homepage,
  // phone number info
  phone: '',
  phonePrefixPickerIndex: 0,
  phonePrefixOptionsList: []
};

const PhoneUpsellModalStoreContext = createContext(initialState);

const reducer = (oldState, action) => {
  switch (action.type) {
    case SET_PHONE_NUMBER:
      return {
        ...oldState,
        phone: action.phone,
        errorMessage: ''
      };
    case SET_COUNTRY:
      return {
        ...oldState,
        phonePrefixPickerIndex: action.phonePrefixPickerIndex,
        errorMessage: ''
      };
    case SET_ERROR_MESSAGE:
      return {
        ...oldState,
        errorMessage: action.errorMessage
      };
    case SET_PAGE:
      return {
        ...oldState,
        errorMessage: '',
        pageName: action.pageName
      };
    case SET_PREFIX_OPTIONS_LIST:
      return {
        ...oldState,
        phonePrefixOptionsList: action.phonePrefixOptionsList
      };
    case CLOSE_PHONE_NUMBER_MODAL:
      return {
        ...initialState,
        isOpen: false,
        errorMessage: ''
      };
    default:
      return initialState;
  }
};

const PhoneUpsellModalStateProvider = ({ children }) => {
  const [phoneUpsellState, dispatch] = useReducer(reducer, initialState);

  return (
    <PhoneUpsellModalStoreContext.Provider value={{ phoneUpsellState, dispatch }}>
      {children}
    </PhoneUpsellModalStoreContext.Provider>
  );
};

PhoneUpsellModalStateProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { PhoneUpsellModalStoreContext, PhoneUpsellModalStateProvider };
