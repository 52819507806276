export const phoneUpsellStrings = {
  // translation strings for phone number verification
  ActionEdit: 'Action.Edit',
  ActionOk: 'Action.Ok',
  ActionDone: 'Action.Done',
  ActionContinue: 'Action.Continue',
  ActionAddPhoneNumber: 'Action.AddPhoneNumber',
  ActionChangePhoneNumber: 'Action.ChangePhoneNumber',
  ActionUseEmail: 'Action.UseEmail',
  ActionVerify: 'Action.Verify',
  ActionResendCode: 'Action.ResendCode',
  ActionCodeResent: 'Action.CodeResent',
  ActionLogoutWithRisk: 'Action.LogoutWithRisk',
  ActionFinishSetup: 'Action.FinishSetup',
  HeadingVerifyYourPhone: 'Heading.VerifyYourPhone',
  LabelEnterCode: 'Label.EnterCode',
  LabelPhoneNumber: 'Label.PhoneNumber',
  LabelVerifyPhoneNumber: 'Label.VerifyPhoneNumber',
  LabelVerificationCode: 'Heading.VerificationCode',
  MessageConfirmation: 'Message.Confirmation',
  MessageInvalidSmsCode: 'Message.InvalidSmsCode',
  DescriptionVerificationCodeSmsFeesMayApply: 'Description.VerificationCodeSmsFeesMayApply',
  HeadingPhoneIsVerified: 'Heading.PhoneIsVerified',
  DescriptionPhoneForRecovery: 'Description.PhoneForRecovery',
  ResponsePhoneNumberAlreadyLinked: 'Response.PhoneNumberAlreadyLinked',
  ResponseTooManyVerificationAttempts: 'Response.TooManyVerificationAttempts',
  ResponseErrorPhoneFormatInvalid: 'Response.ErrorPhoneFormatInvalid',
  ResponseInvalidPhoneNumberError: 'Response.InvalidPhoneNumberError',
  ResponseErrorTryAgain: 'Response.ErrorTryAgain'
};

export const emailUpsellStrings = {
  // translation strings for email upsell
  ActionChangeEmail: 'Action.ChangeEmail',
  ActionResendConfirmationEmail: 'Action.ResendConfirmationEmail',
  ActionSendConfirmationEmail: 'Action.SendConfirmationEmail',
  ActionSent: 'Action.Sent',
  ActionLogoutSkip: 'Action.Logout',
  ActionGenericSkip: 'Action.GenericSkip',
  ActionContinue: 'Action.Continue',
  HomePageAddEmailTextOver13: 'Description.PleaseEnterEmail',
  HomePageAddEmailTextUnder13: 'Description.PleaseEnterParentEmail',
  DescriptionAddEmailTextOver13: 'Description.AddEmailTextOver13',
  DescriptionAddEmailTextUnder13: 'Description.AddEmailTextUnder13',
  DescriptionLogoutTextOver13: 'Description.LogoutAddEmailTextOver13',
  DescriptionLogoutTextUnder13: 'Description.LogoutAddEmailTextUnder13',
  DescriptionEnterPassword: 'Description.EnterPassword',
  DescriptionVerifyEmailBody: 'Description.VerifyEmailBody',
  DescriptionAddEmailTextStrongMessaging: 'Description.AddEmailTextStrongMessaging',
  HeadingAddEmailHomePage: 'Heading.PleaseAddEmail',
  HeadingAddEmail: 'Heading.AddEmail',
  HeadingVerifyEmail: 'Heading.VerifyEmail',
  HeadingVerifyOnLogout: 'Heading.VerifyOnLogout',
  HeadingCompleteSetupOnLogout: 'Heading.CompleteSetupOnLogout',
  LabelEmailInputPlaceholderOver13: 'Label.EmailInputPlaceholderOver13',
  LabelEmailInputPlaceholderUnder13: 'Label.EmailInputPlaceholderUnder13',
  LabelPasswordInputPlaceholder: 'Label.PasswordInputPlaceholder',
  MessageConfirmationEmailNotSent: 'Message.ConfirmationEmailNotSent',
  MessageInvalidEmailAddress: 'Message.InvalidEmailAddress',
  MessageWrongPassword: 'Message.WrongPassword'
};
