import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { Button } from 'react-style-guide';
import PropTypes from 'prop-types';

import usePhoneUpsellState from '../hooks/usePhoneUpsellState';
import PhoneNumberInput from './PhoneNumberInput';
import { phoneUpsellStrings } from '../../common/constants/translationConstants';
import { VERIFY_PHONE_NUMBER_PAGE } from '../constants/pageConstants';
import { SET_ERROR_MESSAGE, SET_PAGE } from '../actions/actionTypes';
import { setPhoneNumber } from '../services/phoneService';
import { getErrorMessageFromSubmissionErrorCode } from '../utils/errorUtils';
import InputFieldError from './InputFieldError';
import getErrorCodeFromRequestError from '../../common/utils/requestUtils';
import getSectionValueForPage from '../utils/loggingUtils';
import {
  sendVerificationUpsellEvent,
  getErrorEventWithErrorCodeParam
} from '../../common/utils/loggingUtils';
import events from '../constants/phoneVerificationEventStreamConstants';

const {
  ActionAddPhoneNumber,
  ActionContinue,
  DescriptionVerificationCodeSmsFeesMayApply
} = phoneUpsellStrings;

function AddPhoneNumber({ translate, onHide }) {
  const { phoneUpsellState, dispatch } = usePhoneUpsellState();
  const {
    phone,
    phonePrefixPickerIndex,
    phonePrefixOptionsList,
    pageName,
    origin
  } = phoneUpsellState;
  const section = getSectionValueForPage(pageName);
  const handleContinueClick = async () => {
    const { prefix, code } = phonePrefixOptionsList[phonePrefixPickerIndex];
    sendVerificationUpsellEvent(events.addPhoneContinuePressed, {
      origin,
      section
    });
    await setPhoneNumber({ phone, prefix, countryCode: code })
      .then(() => {
        // TODO: add loading UI
        dispatch({ type: SET_PAGE, pageName: VERIFY_PHONE_NUMBER_PAGE });
      })
      .catch(err => {
        const errorCode = getErrorCodeFromRequestError(err);
        const errorEvent = getErrorEventWithErrorCodeParam(
          events.phoneNumberModalErrorShown,
          errorCode
        );
        sendVerificationUpsellEvent(errorEvent, {
          origin,
          section
        });
        dispatch({
          type: SET_ERROR_MESSAGE,
          errorMessage: getErrorMessageFromSubmissionErrorCode(errorCode)
        });
      });
  };

  return (
    <div>
      <Modal.Header>
        <div className='verification-upsell-title-container'>
          <button type='button' className='verification-upsell-title-button' onClick={onHide}>
            <span className='close icon-close' />
          </button>
          <Modal.Title id='contained-modal-title-vcenter'>
            {translate(ActionAddPhoneNumber)}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='phone-number-submission-container'>
          <p className='verification-upsell-text-body'>
            {translate(DescriptionVerificationCodeSmsFeesMayApply)}
          </p>
          <PhoneNumberInput translate={translate} />
          <InputFieldError translate={translate} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='buttons-section'>
          <Button
            type='button'
            className='accept-btn'
            variant={Button.variants.primary}
            isDisabled={phone.length === 0}
            onClick={() => handleContinueClick()}>
            {translate(ActionContinue)}
          </Button>
        </div>
      </Modal.Footer>
    </div>
  );
}

AddPhoneNumber.propTypes = {
  translate: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
};

export default AddPhoneNumber;
