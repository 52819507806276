const CLOSE_PHONE_NUMBER_MODAL = 'CLOSE_PHONE_NUMBER_MODAL';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const SET_PHONE_NUMBER_SEND_ERROR = 'SET_PHONE_NUMBER_SEND_ERROR';
const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
const SET_COUNTRY = 'SET_COUNTRY';
const SET_PAGE = 'SET_PAGE';
const SET_PREFIX_OPTIONS_LIST = 'SET_PREFIX_OPTIONS_LIST';
export {
  CLOSE_PHONE_NUMBER_MODAL,
  SET_ERROR_MESSAGE,
  SET_PHONE_NUMBER_SEND_ERROR,
  SET_PHONE_NUMBER,
  SET_COUNTRY,
  SET_PAGE,
  SET_PREFIX_OPTIONS_LIST
};
