import React from 'react';
import PropTypes from 'prop-types';
import { withTranslations } from 'react-utilities';
import { translation } from './app.config';
import PhoneUpsellModalContainer from './phoneUpsellModal/container/PhoneUpsellModalContainer';
import { PhoneUpsellModalStateProvider } from './phoneUpsellModal/stores/PhoneUpsellModalStoreContext';

function PhoneUpsellApp({ translate }) {
  return (
    <PhoneUpsellModalStateProvider>
      <PhoneUpsellModalContainer translate={translate} />
    </PhoneUpsellModalStateProvider>
  );
}

PhoneUpsellApp.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslations(PhoneUpsellApp, translation);
