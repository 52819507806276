import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import usePhoneUpsellState from '../hooks/usePhoneUpsellState';
import { CLOSE_PHONE_NUMBER_MODAL } from '../actions/actionTypes';
import { sendVerificationUpsellEvent } from '../../common/utils/loggingUtils';
import events from '../constants/phoneVerificationEventStreamConstants';
import getSectionValueForPage from '../utils/loggingUtils';

// pages
import {
  ADD_PHONE_NUMBER_PAGE,
  VERIFY_PHONE_NUMBER_PAGE,
  ADD_PHONE_SUCCESS_PAGE
} from '../constants/pageConstants';

// components
import AddPhoneNumber from '../components/AddPhoneNumber';
import VerifyPhoneNumber from '../components/VerifyPhoneNumber';
import AddPhoneSuccess from '../components/AddPhoneSuccess';

function PhoneUpsellModalContainer({ translate }) {
  const { phoneUpsellState, dispatch } = usePhoneUpsellState();
  const { isOpen, pageName, origin } = phoneUpsellState;
  const section = getSectionValueForPage(pageName);

  const onHide = () => {
    sendVerificationUpsellEvent(events.phoneNumberModalDismissed, {
      origin,
      section
    });
    dispatch({ type: CLOSE_PHONE_NUMBER_MODAL });
  };

  const modalContent = () => {
    switch (pageName) {
      case ADD_PHONE_NUMBER_PAGE:
        return <AddPhoneNumber translate={translate} onHide={onHide} />;
      case VERIFY_PHONE_NUMBER_PAGE:
        return <VerifyPhoneNumber translate={translate} onHide={onHide} />;
      case ADD_PHONE_SUCCESS_PAGE:
        return <AddPhoneSuccess translate={translate} onHide={onHide} />;
      default:
        return <AddPhoneNumber translate={translate} onHide={onHide} />;
    }
  };

  useEffect(() => {
    sendVerificationUpsellEvent(events.phoneNumberModalShown, {
      origin,
      section
    });
  }, [pageName]);

  return (
    <Modal
      show={isOpen}
      onHide={onHide}
      /* eslint-enable */
      className='verification-modal'
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      scrollable='true'
      centered='true'>
      {modalContent()}
    </Modal>
  );
}

PhoneUpsellModalContainer.propTypes = {
  translate: PropTypes.func.isRequired
};

export default PhoneUpsellModalContainer;
