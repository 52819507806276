import { httpService } from 'core-utilities';
import {
  setPhoneNumberConfig,
  verifyPhoneNumberConfig,
  resendCodeToPhoneNumberConfig,
  getPhonePrefixesConfig
} from '../../common/constants/urlConstants';
import { PhoneSubmissionConstants } from '../constants/phoneConstants';

const { defaultCountryCode } = PhoneSubmissionConstants;

export const getPhonePrefixes = () => {
  const urlConfig = getPhonePrefixesConfig();
  return httpService.get(urlConfig).then(
    ({ data }) => {
      let defaultPrefix;
      // Find default option and put that at the top of the list
      data.filter(p => {
        if (p.code !== defaultCountryCode) {
          return true;
        }
        defaultPrefix = p;
        return false;
      });

      if (defaultPrefix) {
        data.unshift(defaultPrefix);
      }
      return data;
    },
    e => {
      return false;
    }
  );
};

export const setPhoneNumber = ({ phone, prefix, countryCode }) => {
  const urlConfig = setPhoneNumberConfig();
  const formData = {
    countryCode,
    prefix,
    phone
  };
  return httpService.post(urlConfig, formData);
};

export const verifyWithCode = (code = '') => {
  const formData = {
    code
  };
  const urlConfig = verifyPhoneNumberConfig();
  return httpService.post(urlConfig, formData);
};

export const resendCode = formData => {
  const urlConfig = resendCodeToPhoneNumberConfig();
  return httpService.post(urlConfig, formData).then(
    ({ data }) => {
      return true;
    },
    e => {
      return false;
    }
  );
};
