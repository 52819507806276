import { EnvironmentUrls } from 'Roblox';

const {
  accountSettingsApi,
  authApi,
  apiGatewayUrl,
  apiProxyUrl,
  accountInformationApi
} = EnvironmentUrls;

const getPhonePrefixesConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${apiProxyUrl}/v1/countries/phone-prefix-list`
});

const getEmailUrlConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${accountSettingsApi}/v1/email`
});

const getEmailVerificationUrlConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${accountSettingsApi}/v1/email/verify`
});

const getMetadataV2UrlConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${authApi}/v2/metadata`
});

const setPhoneNumberConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${accountInformationApi}/v1/phone`
});

const verifyPhoneNumberConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${accountInformationApi}/v1/phone/verify`
});

const resendCodeToPhoneNumberConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${accountInformationApi}/v1/phone/resend`
});

const getLogoutContactMethodModalExperimentConfig = () => ({
  retryable: true,
  withCredentials: true,
  url: `${apiGatewayUrl}/product-experimentation-platform/v1/projects/1/layers/Website.Logout.ContactMethodModal/values`
});

const verificationUpsellModalLogoutExperimentParameters = {
  header: 'alt_title',
  body: 'alt_body',
  primaryButton: 'alt_primary_button_text',
  secondaryButton: 'alt_secondary_button_text'
};

export {
  getPhonePrefixesConfig,
  getEmailUrlConfig,
  getEmailVerificationUrlConfig,
  getMetadataV2UrlConfig,
  setPhoneNumberConfig,
  verifyPhoneNumberConfig,
  resendCodeToPhoneNumberConfig,
  getLogoutContactMethodModalExperimentConfig,
  verificationUpsellModalLogoutExperimentParameters
};
