import {
  PhoneNumberSubmissionErrorCodes,
  VerificationCodeSubmissionErrorCodes
} from '../constants/phoneConstants';

import { phoneUpsellStrings } from '../../common/constants/translationConstants';

const {
  ResponsePhoneNumberAlreadyLinked,
  ResponseTooManyVerificationAttempts,
  ResponseErrorPhoneFormatInvalid,
  ResponseInvalidPhoneNumberError,
  ResponseErrorTryAgain,
  MessageInvalidSmsCode
} = phoneUpsellStrings;

const {
  PhoneNumberAlreadyLinkedCode,
  TooManySubmissionAttemptsCode,
  InvalidPhoneNumber,
  InvalidPhoneNumberType
} = PhoneNumberSubmissionErrorCodes;

const { InvalidCode, TooManyVerificationAttemptsCode } = VerificationCodeSubmissionErrorCodes;

export const getErrorMessageFromSubmissionErrorCode = errorCode => {
  switch (errorCode) {
    case PhoneNumberAlreadyLinkedCode:
      return ResponsePhoneNumberAlreadyLinked;
    case TooManySubmissionAttemptsCode:
      return ResponseTooManyVerificationAttempts;
    case InvalidPhoneNumber:
      return ResponseErrorPhoneFormatInvalid;
    case InvalidPhoneNumberType:
      return ResponseInvalidPhoneNumberError;
    default:
      return ResponseErrorTryAgain;
  }
};

export const getErrorMessageFromVerificationErrorCode = errorCode => {
  switch (errorCode) {
    case TooManyVerificationAttemptsCode:
      return ResponseTooManyVerificationAttempts;
    case InvalidCode:
      return MessageInvalidSmsCode;
    default:
      return ResponseErrorTryAgain;
  }
};
