import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import PropTypes from 'prop-types';

import usePhoneUpsellState from '../hooks/usePhoneUpsellState';
import { phoneUpsellStrings } from '../../common/constants/translationConstants';
import { CLOSE_PHONE_NUMBER_MODAL } from '../actions/actionTypes';
import getSectionValueForPage from '../utils/loggingUtils';
import events from '../constants/phoneVerificationEventStreamConstants';
import { sendVerificationUpsellEvent } from '../../common/utils/loggingUtils';

function AddPhoneSuccess({ translate }) {
  const { phoneUpsellState, dispatch } = usePhoneUpsellState();
  const { origin, pageName } = phoneUpsellState;
  const { HeadingPhoneIsVerified, DescriptionPhoneForRecovery, ActionDone } = phoneUpsellStrings;

  const section = getSectionValueForPage(pageName);

  const handleDoneClick = () => {
    sendVerificationUpsellEvent(events.phoneAddedDonePressed, {
      origin,
      section
    });
    dispatch({ type: CLOSE_PHONE_NUMBER_MODAL });
  };

  return (
    <div>
      <Modal.Body>
        <div className='phone-number-verification-upsell-image' />
        <div className='verification-upsell-title-container phone-number-verification-success-page-title page-title'>
          <Modal.Title id='contained-modal-title-vcenter'>
            {translate(HeadingPhoneIsVerified)}
          </Modal.Title>
        </div>
        <p className='phone-number-verification-text-body'>
          {translate(DescriptionPhoneForRecovery)}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type='button'
          className='btn-cta-md btn-max-width phone-number-verify-button'
          onClick={handleDoneClick}>
          {translate(ActionDone)}
        </button>
      </Modal.Footer>
    </div>
  );
}

AddPhoneSuccess.propTypes = {
  translate: PropTypes.func.isRequired
};

export default AddPhoneSuccess;
